/*
 * This combined file was created by the DataTables downloader builder:
 *   https://datatables.net/download
 *
 * To rebuild or modify this file with the latest versions of the included
 * software please visit:
 *   https://datatables.net/download/#bs4/dt-1.10.16
 *
 * Included libraries:
 *   DataTables 1.10.16
 */
iframe {
  border:none !important;
}

table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
}

fieldset {
  border: 1px solid #264972;
  margin: 5px;
}

legend {
  width: auto;
  font-size: unset;
}

a {
  color: inherit !important;
}

input {
  border-width: 1px;
  border-color: rgb(169, 169, 169) !important;
}

.SmallMat-spinner {
  width: 50px !important;
  height: 50px !important;
}

  .SmallMat-spinner svg {
    width: 50px !important;
    height: 50px !important;
  }

/*---------- invalid section ----------*/

input[type='text'].invalid,
input[type='tel'].invalid,
input[type='number'].invalid,
input[type='email'].invalid {
  border: 1px solid red !important;
  color: red !important;
}

select.invalid {
  border: 1px solid red !important;
}

mat-form-field.invalid
.mat-form-field-wrapper
.mat-form-field-flex
.mat-form-field-infix
.mat-form-field-label-wrapper
.mat-form-field-label,
.mat-form-field.invalid
.mat-form-field-wrapper
.mat-form-field-flex
.mat-form-field-suffix
mat-datepicker-toggle {
  color: red !important;
}

mat-form-field.invalid .mat-form-field-underline {
  background-color: red !important;
}

/*---------------------------------------*/

/*------- Bootstrap new class -----------*/

.col-md-5_2 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 768px) {
  .col-md-5_2 {
    flex: 0 0 45.8333335%;
    max-width: 45.8333335%;
  }
}

/*---------------------------------------*/

.label {
  border: 0px !important;
  cursor: default;
  background-color: transparent !important;
  color: inherit !important;
}

.active {
  background-color: inherit !important;
  font-weight: bold;
}

/* Style the sidenav links and the dropdown button */
.dropdown-btn {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
  background: #264972 url(assets/Images/menu_bg.png) repeat-x;
}

  /* On mouse-over */
  .dropdown-btn:hover {
    color: #f1f1f1;
  }

/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px;
}

.button {
  border: 1px solid #264972;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 12px;
  font-family: arial, helvetica, sans-serif;
  padding: 10px 10px 10px 10px;
  text-decoration: none;
  display: inline-block;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.3);
  font-weight: bold;
  color: #ffffff;
  background-color: #264972;
  background-image: linear-gradient(to bottom, #264972, #264972);
}

  .button:hover {
    border: 1px solid #264972;
    background-color: #8b9fb7;
    background-image: linear-gradient(to bottom, #8b9fb7, #264972);
  }

.normalText {
  font-family: georgia, serif;
  font-size: 14px;
  text-align: left;
}

.normalButton {
  border: 1px solid #264972;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 12px;
  font-family: arial, helvetica, sans-serif;
  padding: 2px 2px 2px 2px;
  text-decoration: none;
  display: inline-block;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.3);
  font-weight: bold;
  color: #ffffff;
  background-color: #264972;
  background-image: linear-gradient(to bottom, #264972, #264972);
  margin-top: 26px;
}

  .normalButton:hover {
    border: 1px solid #264972;
    background-color: #8b9fb7;
    background-image: linear-gradient(to bottom, #8b9fb7, #264972);
  }

.smallTextBox {
  width: 40px !important;
  font-family: georgia, serif;
  font-size: 12px;
  margin-bottom: 1px;
}

.normalTextBox {
  width: 100px !important;
  font-family: georgia, serif;
  font-size: 12px;
  margin-bottom: 1px;
}

.extendedTextBox {
  width: 150px !important;
  font-family: georgia, serif;
  font-size: 12px;
  margin-bottom: 1px;
}

.longTextBox {
  width: 200px;
  font-family: georgia, serif;
  font-size: 12px;
  margin-bottom: 1px;
}

.veryLongTextBox {
  width: 400px;
  font-family: georgia, serif;
  font-size: 12px;
  margin-bottom: 1px;
}

.fullWidthListBox {
  border: 1px solid #264972;
  font-family: georgia, serif;
  font-size: 12px;
  width: 100%;
  height: 150px;
  overflow: auto;
}

.normalListBox {
  border: 1px solid #264972;
  font-family: georgia, serif;
  font-size: 12px;
  width: 125px;
  height: 150px;
  overflow: auto;
}

.longListBox {
  border: 1px solid #264972;
  font-family: georgia, serif;
  font-size: 12px;
  width: 250px;
  height: 150px;
  overflow: auto;
}

.veryLongListBox {
  border: 1px solid #264972;
  font-family: georgia, serif;
  font-size: 12px;
  width: 350px;
  height: 150px;
  overflow: auto;
}

.normalDropdownList {
  font-family: georgia, serif;
  font-size: 12px;
  width: 125px;
}

.longDropdownList {
  font-family: georgia, serif;
  font-size: 12px;
  width: 250px;
}

.veryLongDropdownList {
  font-family: georgia, serif;
  font-size: 12px;
  width: 100%;
}

.fourxDropDownList {
  font-family: georgia, serif;
  font-size: 12px;
  width: 500px;
}

.SelectedGridRow {
  background-color: #e1e1e1 !important;
  color: black;
}

.Grid {
  background-color: #fff;
  margin: 5px 0 10px 0;
  border: solid 1px #264972;
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
}

  .Grid td {
    padding: 2px;
    border: solid 1px #c1c1c1;
    color: black;
  }

  .Grid th {
    padding: 2px 2px 2px 2px;
    color: black;
    background: #8b9fb7;
    border-left: solid 1px #b30808;
    font-weight: normal;
    text-align: center;
  }

  .Grid .alt {
    background: #fcfcfc;
  }

  .Grid .pgr {
    background: #424242;
  }

    .Grid .pgr table {
      margin: 5px 0;
    }

    .Grid .pgr td {
      border-width: 0;
      padding: 0 6px;
      border-left: solid 1px #666;
      font-weight: bold;
      color: black;
      line-height: 12px;
    }

    .Grid .pgr a {
      color: black;
      text-decoration: none;
    }

      .Grid .pgr a:hover {
        color: black;
        text-decoration: none;
      }

.hidden {
  display: none !important;
}

table.dataTable td,
table.dataTable th {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

  table.dataTable td.dataTables_empty,
  table.dataTable th.dataTables_empty {
    text-align: center;
  }

table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length select {
  width: 75px;
  display: inline-block;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}

  div.dataTables_wrapper div.dataTables_filter label {
    font-weight: normal;
    white-space: nowrap;
    text-align: left;
  }

  div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em;
    display: inline-block;
    width: auto;
  }

div.dataTables_wrapper div.dataTables_info {
  padding-top: 0.85em;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
    justify-content: flex-end;
  }

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0;
}

table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 30px;
}

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}

  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:after {
    position: absolute;
    bottom: 0.9em;
    display: block;
    opacity: 0.3;
  }

  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:before {
    right: 1em;
    content: '\2191';
  }

  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:after {
    right: 0.5em;
    content: '\2193';
  }

  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_desc:after {
    opacity: 1;
  }

  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:after {
    opacity: 0;
  }

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

  div.dataTables_scrollBody table thead .sorting:after,
  div.dataTables_scrollBody table thead .sorting_asc:after,
  div.dataTables_scrollBody table thead .sorting_desc:after {
    display: none;
  }

  div.dataTables_scrollBody table tbody tr:first-child th,
  div.dataTables_scrollBody table tbody tr:first-child td {
    border-top: none;
  }

div.dataTables_scrollFoot > .dataTables_scrollFootInner {
  box-sizing: content-box;
}

  div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
    margin-top: 0 !important;
    border-top: none;
  }

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
}

table.dataTable.table-sm > thead > tr > th {
  padding-right: 20px;
}

table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
  top: 5px;
  right: 0.85em;
}

table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
  top: 5px;
}

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0;
}

  table.table-bordered.dataTable th:last-child,
  table.table-bordered.dataTable th:last-child,
  table.table-bordered.dataTable td:last-child,
  table.table-bordered.dataTable td:last-child {
    border-right-width: 0;
  }

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
}

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}

  div.table-responsive > div.dataTables_wrapper > div.row > div[class^='col-']:first-child {
    padding-left: 0;
  }

  div.table-responsive > div.dataTables_wrapper > div.row > div[class^='col-']:last-child {
    padding-right: 0;
  }

.input-group.date .input-group-addon {
  cursor: pointer;
}

.input-daterange {
  width: 100%;
}

  .input-daterange input {
    text-align: center;
  }

    .input-daterange input:first-child {
      border-radius: 3px 0 0 3px;
    }

    .input-daterange input:last-child {
      border-radius: 0 3px 3px 0;
    }

  .input-daterange .input-group-addon {
    width: auto;
    min-width: 16px;
    padding: 4px 5px;
    line-height: 1.42857143;
    text-shadow: 0 1px 0 #fff;
    border-width: 1px 0;
    margin-left: -5px;
    margin-right: -5px;
  }

/*
/* Utiliser @primary, @secondary, etc. pour les couleurs.
/* Utiliser @grey, @darkgray, etc. pour les gris.
/* Utiliser @serif, @sansserif, etc. pour les fonts
*/
@keyframes nav_appear {
  from {
    top: -100px;
  }

  to {
    top: 80px;
  }
}

.button-link {
  display: inline-block;
  background-color: #4dabba;
  color: #fff;
  border-radius: 20px;
  box-shadow: none;
  border: 1px solid #4dabba;
  box-shadow: 0px 3px 6px -1px rgba(77, 171, 186, 0.4);
  transition: 0.3s all ease;
  padding: 10px 30px;
}

  .button-link.slow {
    transition: 0.6s all ease;
  }

  .button-link:hover,
  .button-link:focus {
    background-color: #429dab;
    box-shadow: none;
    color: #fff;
  }

  .button-link.dark {
    background-color: #fff;
    border: 1px solid #f7f7f7;
    color: #1c1c1c;
    box-shadow: 0px 3px 6px -1px rgba(28, 28, 28, 0.4);
    padding: 3px 10px;
  }

    .button-link.dark:hover {
      background-color: #4dabba;
      color: #fff;
      border-color: #4dabba;
      box-shadow: none;
    }

  .button-link.white {
    background-color: #fff;
    border: 1px solid #f7f7f7;
    color: #1c1c1c;
    box-shadow: 0px 3px 6px -1px rgba(28, 28, 28, 0.4);
    padding: 10px 25px;
  }

    .button-link.white:hover {
      background-color: #4dabba;
      color: #fff;
      border-color: #4dabba;
      box-shadow: none;
    }

  .button-link.cancel:hover {
    background-color: #f74c2d;
    color: #fff;
    border-color: #f74c2d;
    box-shadow: none;
  }

.fa-times-thin:before {
  content: '\00d7';
}

.flex-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

  .flex-grid .grid-item {
    max-width: 100%;
    width: 100%;
    padding: 0 10px;
  }

  .flex-grid.x-start {
    justify-content: flex-start;
  }

  .flex-grid.x-end {
    justify-content: flex-end;
  }

  .flex-grid.x-center {
    justify-content: center;
  }

  .flex-grid.x-between {
    justify-content: space-between;
  }

  .flex-grid.x-around {
    justify-content: space-around;
  }

  .flex-grid.y-start {
    align-items: flex-start;
  }

  .flex-grid.y-end {
    align-items: flex-end;
  }

  .flex-grid.y-center {
    align-items: center;
  }

  .flex-grid.cols-2 .grid-item {
    max-width: 50%;
  }

  .flex-grid.cols-3 .grid-item {
    max-width: 33.33%;
  }

  .flex-grid.cols-4 .grid-item {
    max-width: 25%;
  }

  .flex-grid.cols-5 .grid-item {
    max-width: 20%;
  }

  .flex-grid.cols-6 .grid-item {
    max-width: 16.66%;
  }

/**********************************************************************/
/* GENERAL */
/**********************************************************************/
body {
  font-family: georgia, serif !important;
  font-size: 14px !important;
  background-color: #c7c9c8 !important;
  margin: 0px 0px 0px 0px;
}

.loginbox {
  position: fixed !important;
  max-width: 380px !important;
  top: 50% !important;
  left: 50% !important;
  margin-left: -190px; /* half of width */
  margin-top: -70px; /* half of height */
}

.leftMenu {
  width: calc(18% - 30px) !important;
  max-width: 300px !important;
}

.Menu {
  background: #264972;
}

  .Menu ul li {
    background: #264972 url(/assets/Images/menu_bg.png) repeat-x;
    padding: 4px 2px 4px;
    padding-left: 8px !important;
    border-right: 1px solid #c7c9c8;
    width: 200px;
  }

.nav-item a {
  color: white !important;
}

.dropdown-menu a {
  color: black !important;
}

.navbar {
  padding: 0px !important;
  border-radius: 0px !important;
}

.navbar-expand-lg .navbar-nav {
  flex-wrap: wrap;
}

.dropdown-menu {
  top: unset !important;
  left: unset !important;
}

.nav-link {
  padding: 0px !important;
}

a,
input[type='submit'] {
  transition: all 0.3s ease;
}

  a,
  a:hover,
  a:focus {
    color: #4dabba;
    text-decoration: none;
  }

* {
  outline: none;
}

a:active {
  outline: none;
}

:-moz-any-link:focus {
  outline: none;
}

h1 {
  font-size: 24px;
  margin: 0;
}

h2 {
  font-size: 18px;
  margin: 0 0 5px 0;
}

h3 {
  font-size: 18px;
  margin: 0 0 17px 0;
}

h4 {
  font-size: 14px;
  margin: 0;
}

h5 {
  font-size: calc(15px + 5 * ((100vw - 350px) / 1570));
}

.form-control {
  padding: 0px !important;
  height: 20px !important;
}

@media screen and (max-width: 350px) {
  h5 {
    font-size: 15px;
  }
}

@media screen and (min-width: 1920px) {
  h5 {
    font-size: 20px;
  }
}

h2 strong {
  color: #f74c2d;
  display: block;
  margin: 5px 0 0 0;
}

.page-wrapper {
  overflow: hidden;
}

.main {
  margin-top: 75px;
  margin-left: 280px;
}

.results {
  text-align: right;
  display: block;
  padding: 10px 15px 0 0;
}

.drop-toggle {
  cursor: pointer;
}

.panel-opened {
  overflow: hidden;
}

.modal .box-header {
  margin: 0 0 10px 0;
}

.modal .modal-content {
  border-radius: 0;
  border: none;
}

.modal .modal-header {
  border: none;
  padding: 0;
}

  .modal .modal-header button.close {
    position: absolute;
    left: -47px;
    background-color: #4dabba;
    color: #fff;
    top: 16px;
    font-size: 18px;
    opacity: 1;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    transition: 0.3s all ease;
  }

    .modal .modal-header button.close.slow {
      transition: 0.6s all ease;
    }

    .modal .modal-header button.close:hover {
      background-color: #429dab;
    }

body .table,
body table {
  font-size: 12px;
  padding-bottom: 1rem;
}

  body .table td,
  body table td {
    vertical-align: middle;
  }

  body .table .button-link,
  body table .button-link {
    margin: 0 auto;
    display: inline-block;
  }

  body .table .table-data-button,
  body table .table-data-button {
    padding: 0.5rem;
    text-align: center;
  }

    body .table .table-data-button.d-flex .button-link,
    body table .table-data-button.d-flex .button-link {
      min-width: 200px;
      margin: 5px auto;
    }

  body .table.table-striped tbody tr:nth-of-type(odd),
  body table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f1f1f1;
  }

.button {
  border: 1px solid #264972;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 12px;
  font-family: arial, helvetica, sans-serif;
  padding: 10px 10px 10px 10px;
  text-decoration: none;
  display: inline-block;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.3);
  font-weight: bold;
  color: #ffffff;
  background-color: #264972;
  background-image: linear-gradient(to bottom, #264972, #264972);
}

.logoHeader {
  font-family: Garamond, Georgia, serif;
  font-size: 22px;
  letter-spacing: 4px;
  font-weight: bold;
  color: #264972;
}

.panel .dataTables_wrapper {
  display: flex;
  flex-wrap: wrap;
}

  .panel .dataTables_wrapper table.dataTable {
    width: 100%;
    margin-bottom: 15px !important;
  }

  .panel .dataTables_wrapper .dataTables_info,
  .panel .dataTables_wrapper .dataTables_paginate {
    width: 50%;
  }

  .panel .dataTables_wrapper div.dataTables_info {
    padding-top: 0;
  }

  .panel .dataTables_wrapper div.dataTables_paginate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

    .panel .dataTables_wrapper div.dataTables_paginate .paginate_button {
      display: flex;
      align-items: center;
      border: 1px solid #dee2e6;
      padding: 9px;
      line-height: 30px;
      height: 30px;
      color: #1c1c1c;
      cursor: pointer;
    }

      .panel .dataTables_wrapper div.dataTables_paginate .paginate_button.current {
        color: #4dabba;
      }

      .panel .dataTables_wrapper div.dataTables_paginate .paginate_button.disabled {
        display: none;
      }

      .panel .dataTables_wrapper div.dataTables_paginate .paginate_button.next {
        border-left: none;
      }

      .panel .dataTables_wrapper div.dataTables_paginate .paginate_button.previous {
        border-right: none;
      }

    .panel .dataTables_wrapper div.dataTables_paginate span {
      display: flex;
    }

      .panel .dataTables_wrapper div.dataTables_paginate span .paginate_button {
        border: none;
        border-right: 1px solid #dee2e6;
        border-top: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
      }

        .panel
        .dataTables_wrapper
        div.dataTables_paginate
        span
        .paginate_button:first-child {
          border-left: 1px solid #dee2e6;
        }

/**********************************************************************/
/* 1169 */
/**********************************************************************/
@media (max-width: 1169px) {
  .main {
    margin: 0 0 0 75px;
  }
}

/**********************************************************************/
/* 1169 */
/**********************************************************************/
@media (max-width: 550px) {
  .main {
    margin: 0;
  }

  .button-link {
    font-size: 13px;
  }

  .panel .dataTables_wrapper table.dataTable {
    display: block;
    overflow-x: scroll;
  }

  .panel .dataTables_wrapper .dataTables_info,
  .panel .dataTables_wrapper .dataTables_paginate {
    width: 100%;
    margin: 5px 0;
  }

  body .table .table-data-button .button-link,
  body table .table-data-button .button-link {
    margin: 0 0 10px 0;
  }
}

/**********************************************************************/
/* HEADER */
/**********************************************************************/
.main-nav {
  width: 280px;
  height: 100vh;
  z-index: 44;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
}

  .main-nav .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    width: 100%;
    border-bottom: 1px solid #f1f1f1;
  }

    .main-nav .logo img {
      height: 35px;
      width: auto;
    }

  .main-nav .menu {
    padding: 30px 0;
    list-style-type: none;
    margin: 0;
  }

    .main-nav .menu li a {
      color: #1c1c1c;
      line-height: 1;
      padding: 15px 30px;
      display: flex;
      align-items: center;
      font-size: 14px;
    }

      .main-nav .menu li a i {
        margin-left: auto;
      }

      .main-nav .menu li a:hover {
        color: #4dabba;
        background-color: #f1f1f1;
      }

    .main-nav .menu li.current-menu-item a {
      color: #4dabba;
      background-color: #f1f1f1;
    }

.fixed-bar {
  position: fixed;
  width: 100%;
  z-index: 34;
  height: 75px;
  background-color: #58585a;
  padding: 0 15px 0 295px;
  display: flex;
  align-items: center;
}

  .fixed-bar .button-link {
    padding: 10px;
    font-size: 12px;
    box-shadow: none;
  }

  .fixed-bar .cpa-only {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 0 15px 0 0;
  }

    .fixed-bar .cpa-only .button-link {
      margin: 0 10px 0 0;
    }

      .fixed-bar .cpa-only .button-link:last-child {
        margin: 0;
      }

  .fixed-bar .action {
    display: flex;
    align-items: center;
  }

  .fixed-bar .user-status {
    position: absolute;
    bottom: 0;
    right: -3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #fff;
  }

    .fixed-bar .user-status.online {
      background-color: #39b54a;
    }

    .fixed-bar .user-status.offline {
      background-color: #f44336;
    }

  .fixed-bar .item-dropdown {
    position: relative;
    display: flex;
  }

  .fixed-bar .dropdown {
    list-style-type: none;
    margin: 0;
    padding: 7px 10px 0 10px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e1e1e1;
    position: absolute;
    top: 100%;
    right: 0;
    visibility: hidden;
    opacity: 0;
    margin-top: 10px;
  }

    .fixed-bar .dropdown li {
      display: flex;
      align-items: center;
      min-width: 215px;
      border-bottom: 1px solid #e1e1e1;
    }

      .fixed-bar .dropdown li span {
        padding: 14px 0;
        display: block;
        line-height: 1;
      }

      .fixed-bar .dropdown li a {
        color: inherit;
      }

        .fixed-bar .dropdown li a:hover {
          color: #4dabba;
        }

      .fixed-bar .dropdown li .user-status {
        position: relative;
        bottom: inherit;
        right: inherit;
        margin-left: auto;
      }

      .fixed-bar .dropdown li:last-child {
        border: none;
        margin: 0;
      }

    .fixed-bar .dropdown.drop-active {
      opacity: 1;
      visibility: visible;
    }

    .fixed-bar .dropdown.align-left {
      left: 0;
      right: inherit;
    }

  .fixed-bar .button-nav {
    border: 1px solid #464646;
    font-size: 12px;
    padding: 14px 45px 14px 8px;
    line-height: 1;
    position: relative;
    background-color: #fff;
    color: #1c1c1c;
    border-radius: 4px;
  }

    .fixed-bar .button-nav i {
      color: #4dabba;
      height: 100%;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #f1f1f1;
      position: absolute;
      right: 0;
      top: 0;
    }

  .fixed-bar .clock {
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 15px;
  }

    .fixed-bar .clock .icon-clock {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid #fff;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .fixed-bar .clock .name {
      font-size: 10px;
      display: block;
      line-height: 1;
    }

    .fixed-bar .clock .timer {
      font-size: 18px;
      padding: 0 10px;
    }

    .fixed-bar .clock i {
      cursor: pointer;
    }

    .fixed-bar .clock .toggles {
      background-color: #4dabba;
      width: 37px;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      border-radius: 50%;
    }

      .fixed-bar .clock .toggles i {
        width: 37px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

    .fixed-bar .clock #sw_reset {
      background-color: #d9d9d9;
      margin: 0 0 0 4px;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #58585a;
    }

  .fixed-bar .user-log {
    flex-shrink: 0;
    margin-left: auto;
  }

    .fixed-bar .user-log .in {
      display: flex;
      align-items: center;
      color: #fff;
      position: relative;
    }

    .fixed-bar .user-log .dropdown li span {
      color: #1c1c1c;
    }

    .fixed-bar .user-log .dropdown li a {
      display: flex;
      align-items: center;
      padding: 14px 0;
      line-height: 1;
      color: #4dabba;
      width: 100%;
    }

    .fixed-bar .user-log .dropdown li i {
      margin-left: auto;
    }

    .fixed-bar .user-log .fa-user-circle {
      font-size: 28px;
    }

    .fixed-bar .user-log .user-photo {
      position: relative;
    }

    .fixed-bar .user-log .user {
      margin: 0 4px 0 8px;
    }

/**********************************************************************/
/* 1169 */
/**********************************************************************/
@media (max-width: 1169px) {
  .fixed-bar {
    padding: 0 15px 0 90px;
    min-height: 75px;
    height: auto;
    flex-wrap: wrap;
    position: relative;
  }

    .fixed-bar .user-log {
      width: 100%;
      order: 1;
      text-align: right;
      padding: 5px 0 0 0;
    }

      .fixed-bar .user-log .in {
        justify-content: flex-end;
        font-size: 12px;
      }

    .fixed-bar .cpa-only {
      order: 2;
      padding: 15px 0;
      flex-wrap: wrap;
    }

  .main-nav {
    width: 75px;
    display: flex;
    flex-direction: column;
  }

    .main-nav .logo {
      /*background-image: url('../images/logo-mobile.png');*/
      background-position: center;
      background-size: 60px;
      background-repeat: no-repeat;
    }

      .main-nav .logo img {
        display: none;
      }

    .main-nav .menu {
      padding: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }

      .main-nav .menu::selection {
        width: 0;
        display: none;
      }

      .main-nav .menu::-moz-selection {
        width: 0;
        display: none;
      }

      .main-nav .menu::-webkit-scrollbar {
        width: 0;
        display: none;
      }

      .main-nav .menu::-webkit-scrollbar-track {
        width: 0;
        display: none;
      }

      .main-nav .menu::-webkit-scrollbar-thumb {
        width: 0;
        display: none;
      }

      .main-nav .menu li {
        flex: 1;
      }

        .main-nav .menu li a {
          height: 100%;
          text-align: center;
          padding: 15px;
          font-size: 16px;
        }

          .main-nav .menu li a span {
            display: none;
          }

          .main-nav .menu li a i {
            margin: 0 auto;
          }

        .main-nav .menu li:nth-child(odd) a {
          background-color: #f1f1f1;
        }

        .main-nav .menu li.current-menu-item a {
          background: none;
        }

        .main-nav .menu li.current-menu-item:nth-child(odd) a {
          background-color: #f1f1f1;
        }
}

@media (max-width: 550px) {
  .main-nav {
    width: 100%;
    flex-direction: row;
    height: 60px;
    bottom: 0;
    top: inherit;
  }

    .main-nav .logo {
      background-size: 40px;
      width: 60px;
      height: 60px;
    }

    .main-nav .menu {
      flex-direction: row;
    }

  .fixed-bar {
    padding: 10px 15px;
  }

    .fixed-bar .clock {
      padding: 15px;
      justify-content: center;
      width: 100%;
    }

    .fixed-bar .item-dropdown {
      width: 100%;
    }

      .fixed-bar .item-dropdown .button-nav {
        width: 100%;
      }

    .fixed-bar .cpa-only .action {
      flex-wrap: wrap;
      width: 100%;
    }

      .fixed-bar .cpa-only .action > * {
        width: 100%;
        margin: 0 0 8px 0;
      }

        .fixed-bar .cpa-only .action > * .drop-toggle {
          width: 100%;
        }
}

/**********************************************************************/
/* FOOTER */
/**********************************************************************/
.panel-container {
  background-color: #ececec;
  padding: 20px;
}

  .panel-container .panel {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
    margin: 0 0 25px 0;
  }

    .panel-container .panel .panel-title {
      font-size: 18px;
      color: #00546c;
      margin: 0 0 15px 0;
    }

    .panel-container .panel .box {
      margin: 0 0 20px 0;
      border: 1px solid #e8e8e8;
    }

      .panel-container .panel .box .box-header {
        padding: 10px 15px;
        display: flex;
        align-items: center;
      }

        .panel-container .panel .box .box-header .magnifier {
          margin-left: auto;
          transition: 0.3s all ease;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 1px solid #1c1c1c;
          display: flex;
          align-items: center;
          justify-content: center;
        }

          .panel-container .panel .box .box-header .magnifier.slow {
            transition: 0.6s all ease;
          }

          .panel-container .panel .box .box-header .magnifier:hover {
            background-color: #4dabba;
            color: #fff;
            border-color: #4dabba;
          }

        .panel-container .panel .box .box-header h3 {
          font-size: 15px;
          margin: 0;
        }

        .panel-container .panel .box .box-header small {
          font-size: 12px;
        }

      .panel-container .panel .box .chart {
        padding: 15px;
      }

        .panel-container .panel .box .chart img {
          max-width: 100%;
          width: 100%;
          height: auto;
        }

  .panel-container .folder-header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
  }

    .panel-container .folder-header .actions {
      margin-left: auto;
    }

      .panel-container .folder-header .actions .button-link {
        margin: 0 0 0 20px;
      }

/**********************************************************************/
/* 650 */
/**********************************************************************/
@media (max-width: 650px) {
  .panel-container {
    padding: 15px;
    margin: 0 0 40px 0;
  }

    .panel-container .panel {
      padding: 15px;
    }

    .panel-container .folder-header {
      display: block;
    }

      .panel-container .folder-header .actions .button-link {
        margin: 10px 0 0 0;
      }
}

.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 25px 0;
}

  .filters .input-element label {
    font-size: 10px;
  }

  .filters .form-control {
    height: 40px;
    text-align: left;
    font-size: 12px;
    color: #1c1c1c;
  }

  .filters ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #979797;
    transition: 0.3s all ease;
  }

    .filters ::-webkit-input-placeholder.slow {
      transition: 0.6s all ease;
    }

  .filters ::-moz-placeholder {
    /* Firefox 19+ */
    color: #979797;
    transition: 0.3s all ease;
  }

    .filters ::-moz-placeholder.slow {
      transition: 0.6s all ease;
    }

  .filters :-ms-input-placeholder {
    /* IE 10+ */
    color: #979797;
    transition: 0.3s all ease;
  }

    .filters :-ms-input-placeholder.slow {
      transition: 0.6s all ease;
    }

  .filters :-moz-placeholder {
    /* Firefox 18- */
    color: #979797;
    transition: 0.3s all ease;
  }

    .filters :-moz-placeholder.slow {
      transition: 0.6s all ease;
    }

  .filters.multi-row {
    flex-wrap: wrap;
    justify-content: inherit;
    max-width: 800px;
  }

    .filters.multi-row .input-element {
      margin: 0 15px 15px 0;
    }

.panel .select2-container .select2-selection--single,
.popup .select2-container .select2-selection--single {
  height: 40px;
  border-color: #d7d7d7;
  transition: 0.3s all ease;
}

  .panel .select2-container .select2-selection--single.slow,
  .popup .select2-container .select2-selection--single.slow {
    transition: 0.6s all ease;
  }

  .panel .select2-container .select2-selection--single .select2-selection__clear,
  .popup .select2-container .select2-selection--single .select2-selection__clear {
    margin-right: 30px;
  }

  .panel
  .select2-container
  .select2-selection--single
  .select2-selection__rendered,
  .popup
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
    line-height: 40px;
  }

  .panel .select2-container .select2-selection--single .select2-selection__arrow,
  .popup .select2-container .select2-selection--single .select2-selection__arrow {
    height: 38px;
    border-left: 1px solid #d7d7d7;
    width: 40px;
    text-align: center;
    transition: 0.3s all ease;
  }

    .panel
    .select2-container
    .select2-selection--single
    .select2-selection__arrow.slow,
    .popup
    .select2-container
    .select2-selection--single
    .select2-selection__arrow.slow {
      transition: 0.6s all ease;
    }

    .panel
    .select2-container
    .select2-selection--single
    .select2-selection__arrow:after,
    .popup
    .select2-container
    .select2-selection--single
    .select2-selection__arrow:after {
      content: '\f107';
      font-family: FontAwesome;
      color: #4dabba;
      line-height: 40px;
    }

    .panel
    .select2-container
    .select2-selection--single
    .select2-selection__arrow
    b,
    .popup
    .select2-container
    .select2-selection--single
    .select2-selection__arrow
    b {
      display: none;
    }

.panel .select2-container.select2-container--open .select2-selection--single,
.popup .select2-container.select2-container--open .select2-selection--single {
  border-color: #4dabba;
}

  .panel
  .select2-container.select2-container--open
  .select2-selection--single
  .select2-selection__arrow,
  .popup
  .select2-container.select2-container--open
  .select2-selection--single
  .select2-selection__arrow {
    border-left-color: #4dabba;
  }

body .select2-dropdown {
  border-color: #4dabba;
}

  body input,
  body .select2 span,
  body .select2-dropdown li {
    font-size: 12px;
  }

body
.select2-container--default
.select2-results__option--highlighted[aria-selected] {
  background-color: #4dabba;
}

body
.select2-container--default.select2-container--focus
.select2-selection--multiple {
  border-color: #4dabba;
}

body .select2-container--default .select2-selection--multiple {
  margin: 0 15px 0 0;
  border-radius: 0;
}

  body .select2-container--default .select2-selection--multiple input {
    height: 28px;
  }

input {
  border-color: #464646;
}

[type='search'] {
  flex: 1;
}

[type='search'],
[type='password'],
[type='text'],
[type='email'],
[type='tel'] {
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  width: 100%;
  transition: 0.3s all ease;
}

  [type='search'].slow,
  [type='password'].slow,
  [type='text'].slow,
  [type='email'].slow,
  [type='tel'].slow {
    transition: 0.6s all ease;
  }

  [type='search']:focus,
  [type='password']:focus,
  [type='text']:focus,
  [type='email']:focus,
  [type='tel']:focus {
    outline: 0;
    border-color: #4dabba;
    box-shadow: none;
  }

textarea {
  border-radius: 4px;
  border: 1px solid #d7d7d7;
  padding: 8px 15px;
  transition: 0.3s all ease;
  width: 100%;
  min-height: 110px;
  max-height: 700px;
  font-size: 12px;
}

  textarea.slow {
    transition: 0.6s all ease;
  }

  textarea:focus {
    outline: 0;
    border-color: #4dabba;
    box-shadow: none;
  }

/* disabled checkbox */
[type='checkbox']:disabled:not(:checked) + label:before,
[type='checkbox']:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}

[type='checkbox']:disabled:checked + label:after {
  color: #999;
}

[type='checkbox']:disabled + label {
  color: #aaa;
}

.datepicker table {
  font-size: 13px;
}

.input-element {
  min-width: 200px;
}

  .input-element label {
    font-size: 12px;
    display: block;
    margin: 0 0 2px 0;
  }

  .input-element.long {
    min-width: 415px;
  }

  .input-element.flex-auto {
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding: 0 30px 0 0;
    margin: 0 0 15px 0;
  }

    .input-element.flex-auto .fluid-input {
      flex: 1;
      padding: 0 50px 0 0;
    }

      .input-element.flex-auto .fluid-input input {
        width: 100%;
        font-size: 12px;
      }

  .input-element.required label span {
    color: #e6472a;
  }

  .input-element.error_form label {
    color: #e6472a;
  }

  .input-element.error_form input {
    border-color: #e6472a;
  }

  .input-element.error_form ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #e6472a;
  }

  .input-element.error_form ::-moz-placeholder {
    /* Firefox 19+ */
    color: #e6472a;
  }

  .input-element.error_form :-ms-input-placeholder {
    /* IE 10+ */
    color: #e6472a;
  }

  .input-element.error_form :-moz-placeholder {
    /* Firefox 18- */
    color: #e6472a;
  }

.input-daterange .input-wrapper {
  position: relative;
  margin: 0 10px;
}

  .input-daterange .input-wrapper:after {
    content: '\f073';
    font-family: FontAwesome;
    color: #4dabba;
    line-height: 40px;
    position: absolute;
    top: 0;
    right: 20px;
  }

  .input-daterange .input-wrapper:first-child {
    margin: 0 5px 0 0;
  }

  .input-daterange .input-wrapper:last-child {
    margin: 0 0 0 5px;
  }

.form-rows > div {
  padding: 0 15px;
}

.form-rows .input-element {
  margin: 0 0 17px 0;
}

.form-rows input {
  font-size: 12px;
}

.form-rows p {
  margin: 0 0 8px 0;
}

.form-rows .input-daterange {
  margin: 10px 0 -10px 0;
}

  .form-rows .input-daterange input {
    text-align: left;
    font-size: 12px;
  }

.form-rows .radio-buttons,
.form-rows .checkbox-buttons {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
}

  .form-rows .radio-buttons .input-element,
  .form-rows .checkbox-buttons .input-element {
    min-width: 0;
    margin: 0 15px 0 0;
  }

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-pseudo-checkbox-checked {
  background: #000;
}

  .mat-pseudo-checkbox-checked::after {
    width: 10px !important;
    height: 5px !important;
  }

.mat-list .mat-list-item,
.mat-list .mat-list-option,
.mat-nav-list .mat-list-item,
.mat-nav-list .mat-list-option,
.mat-selection-list .mat-list-item,
.mat-selection-list .mat-list-option {
  display: inline-block !important;
}

@media print {
   iframe {
    padding:0px !important;
    margin:0px !important;
  } 
  .container-fluid {
    padding:0px !important;
    margin:0px !important;
  }
}
